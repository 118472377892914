<template>
  <div>
    <b-card no-body>
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :items="readItems"
        :fields="fields"
      >
        <template #cell(name)="data">
          {{ fixName(data.item.name) }}
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { BCard, BTable } from 'bootstrap-vue'

export default {
  components: { BCard, BTable },
  data() { return { items: [] } },
  computed: {
    readItems() { return this.items },
    fixName() {
      return nameArg => this.$t(`barriers.${nameArg}`)
    },
    fields() {
      return [
        { key: 'name', label: this.$t('barriers.table.name'), sortable: true },
      ]
    },
  },
  async created() { await this.load() },
  methods: {
    async load() {
      this.items = await this.$store.dispatch('barrier/index')
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@media (max-width: 767px){
  .small-devices-filterby{
    display: block;
  }
}
.dropdown button {
    padding: 0px;
}
.em-dropdown .dropdown-menu {
  max-height: 100px;
  overflow-y: hidden;
}
.dropdown-item{
  padding: 0 4px;
}
</style>
